
	<template>
  <div class="edu-doctor">
    <StepNavbar ref="stepNavbar"></StepNavbar>
    <div class="content">
      <p>
        <el-button icon="el-icon-arrow-left" type="text" @click="toLast">上一步</el-button>
      </p>
      <p>
        <el-form ref="form" :model="form" label-width="160px">
          <el-form-item style="padding: 0px 120px 0px 70px;">
            <el-radio-group v-model="form.isGraduated">
              <el-row>
                <el-col :span="12" v-for="item in graduated" :key="item.id" class="text-center">
                  <el-radio :label="item.value">{{item.name}}</el-radio>
                </el-col>
              </el-row>
            </el-radio-group>
          </el-form-item>

          <div class="graduated" v-if="form.isGraduated === 1">
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学历">
                  <el-select v-model="form.education"> 
					
					<el-option v-for="item in educations" :key="item.value" :value="item.value"
						:label="item.name"></el-option>  
						
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="毕业院校">
                  <el-input v-model="form.colleges"></el-input>
                  <span class="tips">*最高学历、学位教育或与所从事专业最相关的学校</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所学专业">
                  <el-input v-model="form.studyMajor"></el-input>
                  <span class="tips" style="margin-left: 0px;">*注意与上述所填写的毕业学校保持一致</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="毕业证书名称/编号">
                  <el-input v-model="form.graduationCert"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="授予时间">
                  <el-date-picker
					 value-format="yyyy/MM/dd"
                      v-model="form.graduationDate" 
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="学位证名称/编号">
                  <el-input v-model="form.degreeCert"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="授予时间">
                  <el-date-picker
					 value-format="yyyy/MM/dd"
                      v-model="form.degreeDate"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="tips" style="width: 100%; margin-left: 0px;">
				研究生含硕士研究生、博士研究生。
				<br/>
              研究生学历包括，研究生Ⅰ类，含：通过全国正式统一研究生招生考试录取， 7年制、8年制本、硕、博连读生，保送研究生等，具有毕业证、学位证或在世界有极大学术成就地位，视为研究生Ⅰ类。
              <br/>
              研究生学历包括，研究生Ⅱ类：非通过全国正式统一研究生招生考试录取，如：社会继续教育、函授等。
            </div>

            <hr/>



			<el-row>
				
				
              <el-col :span="12">
                
				
				
				
				
              </el-col>
			  
             
            </el-row>
			
			
			
			
            <el-row>
              <el-col :span="12">
                <el-form-item label="从事专业">
                  <el-input v-model="form.profession"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="本站注册专业">
                  <el-input v-model="form.registeredMajor"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="工作履历及专业技术特长简述" label-width="210px" style="margin-left: -50px;">
              <el-input   maxlength="500" type="textarea" rows="5" v-model="form.memo" show-word-limit ></el-input>
			  
			  
            </el-form-item>

            <hr/>

            <el-form-item label="医生资格证书名称及种类" label-width="210px" style="margin-left: -50px;">
              <el-checkbox-group v-model="qualificationCertType" style="width: 120%;">
                <el-checkbox :label="item" name="qualificationCertType" :value="item" v-for="item in types"
                             :key="item"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
			
		

            <el-row>
              <el-col :span="12">
                <el-form-item label="资格证书及编号">
                  <el-input v-model="form.qualificationCert"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专业">
                  <el-input v-model="form.qualificationMajor"></el-input>
                  <span class="tips">*含麻醉、药学、影像等专业，仅到中级资格即可</span>
                </el-form-item>
              </el-col>
            </el-row>

		            <el-row>
		              <el-col :span="12">
		                <el-form-item label="相应资格证书证件号码">
		                  <el-input style="width: 658px;" v-model="form.qualificationCertNos"></el-input>
		                </el-form-item>
		              </el-col>
		              
		            </el-row>	
            <el-row>
              <el-col :span="12">
                <el-form-item label="颁发单位（卫生行政部门）" label-width="210px" style="margin-left: -50px;">
                  <el-input v-model="form.qualificationIssuer"></el-input>
                  <span class="tips">*如：湖南省卫生厅/卫健委</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="颁发时间">
                  <el-date-picker
					 value-format="yyyy/MM/dd"
                      v-model="form.qualificationDate"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                  <span class="tips">
                    *与资格证上时间一致
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class="graduated" v-if="form.isGraduated === 0">
            <el-row>
              <el-col :span="12">
                <el-form-item label="在读学校（全称）">
                  <el-input v-model="form.colleges"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专业">
                  <el-input v-model="form.studyMajor"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="在读学历学位">
                  <el-input v-model="form.education"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入校日期">
                  <el-date-picker
					 value-format="yyyy/MM/dd"
                      v-model="form.studyDate"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="学制">
                  <el-input v-model="form.schoolSystem"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入校时间已满">
                  <el-input v-model="form.studyLength"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </div>

          <el-form-item class="text-center">
            <el-button class="btn-next" @click="toNext">下一步</el-button>
          </el-form-item>

        </el-form>


      </p>
    </div>


  </div>
</template>

<script>
  import StepNavbar from "./components/StepNavbar";
  import {getSessionUser} from "../../utils/Storage";

  export default {
    name: 'EduDoctor',
    components: {
      StepNavbar
    },
    data() {
      return {
		  
        form: {
          isGraduated: 1,
        },
		
		doctypes: [{value: 1, name: '中医'}, {value: 2, name: "西医"}, {value: 3, name: "中西结合"}],
        graduated: [{value: 1, name: '已毕业'}, {value: 0, name: "未毕业"}],
        educations: this.educations,
        qualificationCertType: [],
        types: ['乡村医生或助理医师资格证书', '医师资格证书，其他专业初级资格证书', '主治医师资格证书，其他专业中级技术资格证书', '通过评选取得的其他医生资格证书（如副主任医师资格证等）']
      }
    },
    mounted() {
      this.$refs['stepNavbar'].setStepNode(3);

    },

    methods: {

      /**
       * 上一步
       */
      toLast() {
        this.$emit('toLast', 2);
      },

      /**
       * 下一步的操作
       */
      toNext() {
        this.form.accountId = getSessionUser().id;
        this.form.qualificationCertType = this.qualificationCertType.toString();
        this.$emit("toNext", this.form);
      }
    }
  }
</script>

<style scoped>
  .graduated {
    margin-bottom: 20px;
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100% !important;
  }

  .tips {
    font-size: 10px !important;
    width: 120%;
    margin-left: -40px;
    display: block;
    color: #FF2828 !important;
  }

  hr {
    margin: 40px 0;
    background: #D8D8D8;
    border: 0;
    height: 1px;
  }

  .el-checkbox /deep/ .el-checkbox__label {
    font-size: 10px !important;
  }

  .wrap {
    width: 1200px;
    margin: 20px auto;
  }

  .content {
    background: #FFFFFF;
    padding: 20px 90px 50px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    /*display: inline-block;*/
    /*transform: translateX(-12%);*/
    line-height: 50px;
    color: #ffffff;
    font-size: 13px;
  }

  .description {
    margin-bottom: 40px;
  }

  .tips {
    color: #DA575D;
    font-size: 13px;
  }

  p {
    margin-bottom: 0px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }

  .el-row.step {
    height: 50px;
    margin-bottom: 10px;
  }

  .step .el-col {
    background-image: url("../../assets/images/bg-step.png");
    height: 100%;
    background-size: 100% 100%;
  }

  .step .el-col:first-child {
    background-image: url("../../assets/images/bg-step01.png");
  }

  .step .bg-white {
    background-image: url("../../assets/images/bg-step02.png");

  }

  .step .bg-white .title {
    color: #000;
  }

  .el-form {
    padding: 0px 100px;
  }

  .el-form .el-radio-group {
    width: 100%;
  }

  .el-form .btn-next {
    width: 50%;
  }

  .el-form .captcha .text-input {
    padding-left: 0px !important;
  }

  .el-form .captcha .text-captcha {
    height: 35px;
  }

  .el-form .captcha .text-captcha img {
    margin-top: 2px;
    cursor: pointer;
  }

  .el-form .el-radio-group {
    text-align: center;
  }

  .el-form .addr .el-col {
    padding: 0px 2px 0px 0px;
  }

  .el-form .addr .el-col:last-child {
    padding: 0px;
  }

  .el-row.idcard .el-col .el-image {
    width: 100%;
    min-height: 80px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .el-row.idcard .el-col {
    padding: 0px 5px;
  }

  .el-row.idcard .el-col .desc {
    font-size: 12px;
    color: #cccccc;
    text-align: center;
    margin-top: -10px;
  }

  .idcard-tips {
    font-size: 12px;
    color: #F25A60;
  }

  .el-input-group__append, .el-input-group__prepend {
    background-color: #ffffff;
    border-color: #ffffff;
  }

  .btn-idcard {
    position: relative;
    top: -90px;
    opacity: 0.6;
  }
</style>
