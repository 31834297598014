<template>
	<div class="account">
		<StepNavbar></StepNavbar>
		<div class="content">
			<p>
				<el-button icon="el-icon-arrow-left" type="text" @click="toLogin">返回登录</el-button>
			</p>
			
			<p>
				<el-form ref="form" :rules="rules" :model="form" label-width="120px">  
					<el-form-item prop="roleId">
						<el-radio-group v-model="form.roleId">
							<el-row>
								<el-col :span="8" v-for="item in roleList" :key="item.id">
									<el-radio :label="item.id">{{item.name}}</el-radio>
								</el-col>
							</el-row>
						</el-radio-group>
					</el-form-item>




					<el-form-item label="设置登录账号" prop="loginName">
						<el-input autocomplete="new-password" v-model="form.loginName"  st placeholder="6至20位英文大写字母、英文小写字母、阿拉伯数字（必须包含）中任意至少2项的组合；如：lys119">
						</el-input>
					</el-form-item>
					<!--        <el-form-item label="验证码" prop="captcha">
            <el-row :gutter="10" class="captcha">
              <el-col :span="10" class="text-input">
                <el-input v-model="form.captcha"></el-input>
              </el-col>
              <el-col :span="6" class="text-captcha">
                <img :src="captcha" width="100%" height="30px" @click="refreshImageCaptcha" ref="imageCaptcha">
              </el-col>
            </el-row>
          </el-form-item> -->
					<el-form-item label="设置密码" prop="loginPassword">
						<el-input v-model="form.loginPassword" autocomplete="new-password"  placeholder="6至20位英文大写字母、英文小写字母、阿拉伯数字、特殊符号中任意3项的组合；"    show-password></el-input>
					</el-form-item>
					<el-form-item label="再次输入密码" prop="againLoginPassword">
						<el-input v-model="form.againLoginPassword" placeholder="6至20位英文大写字母、英文小写字母、阿拉伯数字、特殊符号中任意3项的组合；"  show-password></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="telphone">
						<el-input v-model="form.telphone"></el-input>
					</el-form-item>
					<el-form-item label="短信验证码" prop="smsVerificationCode">
						<el-row :gutter="10" class="captcha">
							<el-col :span="10" class="text-input">
								<el-input v-model="form.smsVerificationCode"></el-input>
							</el-col>
							<el-col :span="6" class="text-captcha">
								<el-button :disabled="btnSMSText !== btnSMSDefText" @click="sendSMSCaptcha">
									{{btnSMSText}}
								</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item class="text-center">
						<el-checkbox v-model="agreeProtocol">
							点击阅读
							<a @click="showProtocol" style="color: #DD7884;">用户协议</a>
							，同意并注册
						</el-checkbox>
					</el-form-item>
					<el-form-item class="text-center">
						<el-button ref="btnNext" class="btn-next" @click="toNext">下一步</el-button>
						<!--<el-button ref="btnNext" class="btn-next" @click="register2">1立即注册</el-button> -->
					</el-form-item>
				</el-form>
			</p>
		</div>
		
	 
		
		
	<el-dialog  title="提示" :visible.sync="dialogVisible"  width="50%"  :before-close="handleClose">
		  <span>
			  用户协议
		  </span>
		  <span slot="footer" class="dialog-footer">
		  <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
		  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
	
</template>

<script>
	import axios from "axios";
	
	
	
	import {
		getCaptha,
		getRoleList,
		sendSMSValifitaionCode
	} from "../../api/LoginApi";
	import StepNavbar from "./components/StepNavbar";
	import {
		getSessionRegister
	} from "../../utils/Storage";

	export default {
		name: 'Account',
		components: {
			StepNavbar
		},
		data() {
			return {
				 dialogVisible: false,
				is_gomess:0,

				roleId: sessionStorage.getItem('roleId'),
				form: {
					roleId: 2
				},
				captcha: getCaptha(),
				btnSMSText: "发送验证码",
				btnSMSDefText: "发送验证码",
				agreeProtocol: false,
				roleList: [{
					'name': '患者',
					'id': 1
				}, {
					'name': '医生',
					'id': 2
				}, {
					'name': '企业',
					'id': 3
				}],
				rules: {
					roleId: [{
						required: true,
						message: "请选择角色",
						trigger: 'blur'
					}],
					loginName: [{ 
						required: true,
						message: "6至20位英文大写字母、英文小写字母、阿拉伯数字（必须包含）中任意至少2项的组合；如：lys119；",
						trigger: 'blur'
					}, 
					{
						validator: (rule, value, cb) => {
								var g = 0;
								
								if (/[0-9]/.test(value)) {
								    g++;
								}   
								console.log('数字:'+g);
								
								
								//特殊符号
								let specialKey = "[@`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
								for (let i = 0; i < value.length; i++) {
								  if (specialKey.indexOf(value.substr(i, 1)) != -1) {
								    g++;
									break;
								  }
								}   
								console.log('特殊:'+g);
								//小写
								// if (/[a-z]/i.test(value)) {
								//     g++;
								// }  
								
								
								//大写
								for (let i = 0; i < value.length; i++) {
									let str2 = value.substr(i, 1); 
									if (/[a-z]/i.test(str2)) {
									    if(str2 == str2.toUpperCase()){
									           g++;  //大写
									    		break;
									      }  
									}  
								    
								}   
								console.log('大写:'+g);
									
								
								//小写
								for (let i = 0; i < value.length; i++) {
									let str3 = value.substr(i, 1); 
									if (/[a-z]/i.test(str3)) {
									    if(str3 == str3.toLowerCase()){
											g++;  //大写
									    	break;
									    }  
									}   
								}   	 
								console.log('小写:'+g);
								console.log('长度:'+value.length);
								var nums = /[0-9]/.test(value); 
								
								if(g<2 || (value.length>20 || value.length<6) || !nums ){
									  cb(new Error('6至20位英文大写字母、英文小写字母、阿拉伯数字（必须包含）中任意至少2项的组合；如：lys119；'))
								}
								
								   
							}
						}, 
					],
					captcha: [{
						required: true,
						message: "验证码不能为空",
						trigger: 'blur'
					}],
					loginPassword: [{
						required: true,
						message: "6至20位英文大写字母、英文小写字母、阿拉伯数字、特殊符号中任意3项的组合",
						trigger: 'blur'
					},
						{
							validator: (rule, value, cb) => { 
								 
								var g = 0;
								
								if (/[0-9]/.test(value)) {
								    g++;
								}   
								console.log('数字:'+g);
								
								
								//特殊符号
								let specialKey = "[@`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
								for (let i = 0; i < value.length; i++) {
								  if (specialKey.indexOf(value.substr(i, 1)) != -1) {
								    g++;
									break;
								  }
								}   
								console.log('特殊:'+g); 
								
								
								//大写
								for (let i = 0; i < value.length; i++) {
									let str2 = value.substr(i, 1); 
									if (/[a-z]/i.test(str2)) {
									    if(str2 == str2.toUpperCase()){
									           g++;  //大写
									    		break;
									      }  
									}  
								    
								}   
								console.log('大写:'+g);
									
								
								//小写
								for (let i = 0; i < value.length; i++) {
									let str3 = value.substr(i, 1); 
									if (/[a-z]/i.test(str3)) {
									    if(str3 == str3.toLowerCase()){
									           g++;  //大写
									    		break;
									      }  
									}   
								}   	 
								console.log('小写:'+g);
								
								if(g<3 || (value.length>20 || value.length<6)){
									  cb(new Error('6至20位英文大写字母、英文小写字母、阿拉伯数字、特殊符号中任意3项的组合'))
								}
								   
							}
						}],
					againLoginPassword: [{
						required: true,
						message: "再次输入密码不能为空",
						trigger: 'blur'
					},
						{
							validator: (rule, value, cb) => {
								
								if(value!=this.form.loginPassword){
									cb(new Error('两次密码输入不一致')) 
								} 
								   
							}
						}
					
					],
					telphone: [
						{
						required: true,
						message: "手机号有误",
						trigger: 'blur'
					},
					{
						validator: (rule, value, cb) => { 
							
							let reg = /^1\d{10}$/
							    if (!reg.test(value)) {
							       cb(new Error('手机号有误'))
							    }   
							   
						}
					}
					],
					// smsVerificationCode: [{
					// 	required: true,
					// 	message: "短信验证码不能为空",
					// 	trigger: 'blur'
					// },
					
					//]
				}
			}


		},
		mounted() {
			// 初始化加载角色列表
			getRoleList().then(arr => {
				this.roleList = arr;
			})
		},

		methods: {
			 

			//表单情况
			isPass(){	
				//手机
				var g = 0;
				var value = this.form.loginName;  
				if (/[0-9]/.test(value)) {
				    g++;
				}   
				 
				console.log('数字:'+g);
			
				
				//特殊符号
				let specialKey = "[@`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
				for (let i = 0; i < value.length; i++) {
				  if (specialKey.indexOf(value.substr(i, 1)) != -1) {
				    g++;
					break;
				  }
				}   
				console.log('特殊:'+g);
				//小写
				// if (/[a-z]/i.test(value)) {
				//     g++;
				// }  
				
				
				//大写
				for (let i = 0; i < value.length; i++) {
					let str2 = value.substr(i, 1); 
					if (/[a-z]/i.test(str2)) {
					    if(str2 == str2.toUpperCase()){
					           g++;  //大写
					    		break;
					      }  
					}  
				    
				}   
				console.log('大写:'+g);
					
				
				//小写
				for (let i = 0; i < value.length; i++) {
					let str3 = value.substr(i, 1); 
					if (/[a-z]/i.test(str3)) {
					    if(str3 == str3.toLowerCase()){
							g++;  //大写
					    	break;
					    }  
					}   
				}   	 
				console.log('小写:'+g);
				console.log('长度:'+value.length);
				var nums = /[0-9]/.test(value); 
				
				if(g<2 || (value.length>20 || value.length<6) || !nums ){ 
				 	return false;
				 }
				
				//密码
				var g = 0;
				var value = this.form.loginPassword;
				
				
				if (/[0-9]/.test(value)) {
				    g++;
				}   
				console.log('数字:'+g);
				
				
				//特殊符号
				for (let i = 0; i < value.length; i++) {
				  if (specialKey.indexOf(value.substr(i, 1)) != -1) {
				    g++;
					break;
				  }
				}   
				console.log('特殊:'+g); 
				
				
				//大写
				for (let i = 0; i < value.length; i++) {
					let str2 = value.substr(i, 1); 
					if (/[a-z]/i.test(str2)) {
					    if(str2 == str2.toUpperCase()){
					           g++;  //大写
					    		break;
					      }  
					}  
				    
				}   
				console.log('大写:'+g);
					
				
				//小写
				for (let i = 0; i < value.length; i++) {
					let str3 = value.substr(i, 1); 
					if (/[a-z]/i.test(str3)) {
					    if(str3 == str3.toLowerCase()){
					           g++;  //大写
					    		break;
					      }  
					}   
				}   	 
				console.log('小写:'+g);
				
				if(g<3 || (value.length>20 || value.length<6)){
						return false;
				 }
				
				
				//两次密码
				if(this.form.againLoginPassword!=this.form.loginPassword){
						return false;
				} 
				
				//手机号
				let reg = /^1\d{10}$/
				    if (!reg.test(this.form.telphone)) {
						return false;
				}   
				
				return true;
				   
				   
				
				
			},
			 

			 
			/**
			 * 刷新图片验证码
			 */
			refreshImageCaptcha() {
				this.$refs['imageCaptcha'].src = getCaptha() + "?" + Math.random()
			},

			/**
			 * 发送短息验证码
			 */
			sendSMSCaptcha() { 
				 //18201293717 
				this.$refs['form'].validate((valid) => { 
				  //  if (valid) {  
					 //   this.is_go_mess = true
				  //  }  else{
						// is_go = false 
					   
				  //  } 
				});	
			 
					if(!this.isPass()){ 
						return false;
						
					} 
					 
				
					
							const params3 = new URLSearchParams();
							params3.append('name', this.form.loginName);
					 
							axios.post(this.apiUrl + '/api/login/isLoginName', params3)
								.then((response) => {
									 
									if (response.data.code == 1) {
										this.$message.error('抱歉，此账号已有人注册，请选择其他账号进行注册！');
										return;
									} else {
										this.captchaCountDown();
							
										const params2 = new URLSearchParams();
										params2.append('phone', this.form.telphone);
							
										axios.post(this.apiUrl + '/api/login/sendMess', params2)
											.then((response) => {
												this.$message.alert('发送成功')
											})
							
							
											.catch(function(error) {
												console.log(error);
											});
									}
								})
							
								.catch(function(error) {
									console.log(error);
								});
					
						 
						 
			  
				
				
				 
					



					// sendSMSValifitaionCode(this.form.telphone).then(res => {
					//   this.captchaCountDown();
					//   this.$message.alert('发送成功')
					// }) 
			},

			/**
			 * 验证码重新发送的倒计时
			 */
			captchaCountDown() {
				let suffixStr = "重新发送";
				let startSecond = 120;
				this.btnSMSText = startSecond + suffixStr;
				let timer = setInterval(() => {
					startSecond--;
					this.btnSMSText = startSecond + suffixStr;
					if (startSecond <= 0) {
						clearInterval(timer);
						this.btnSMSText = this.btnSMSDefText
					}
				}, 1000)
			},

			/**
			 * 返回登录页面
			 */
			toLogin() {
				this.$router.replace('/login')
			},

			/**
			 * 打开协议
			 */
			showProtocol() { 
				this.dialogVisible = true;
				//this.$emit("openProtocol")
			},

			/**
			 * 点击下一步的操作  8413
			 */
			toNext() {
				
				
				//测试快捷注册-----------
				// this.form.accountId = this.form.id || '';
				// this.$emit("toNext", this.form);
				//测试快捷注册------------ 
				
 
				const params2 = new URLSearchParams();
				params2.append('phone', this.form.telphone);
				params2.append('code', this.form.smsVerificationCode);



				// 短信验证拦截
				if(!this.agreeProtocol){
					this.$message.error('请先阅读用户协议!');
					return false;
				}
				axios.post(this.apiUrl + '/api/login/registerCode', params2)
					.then((response) => {
						if (response.data.code == 3) {
							this.$message.error('验证码错误！')
						} else {
							
							
							this.register();
							this.form.accountId = this.form.id || '';
							this.$emit("toNext", this.form);
							// this.$refs['form'].validate(valid => {

							// 	console.log("=====================");
							// 	console.log(valid);
							// 	console.log("=====================");

							// 	// agreeProtocol 同意
							// 	//if (valid && this.agreeProtocol) {
							// 	if (valid) {
							// 		this.register();
							// 		this.form.accountId = this.form.id || '';
							// 		this.$emit("toNext", this.form);
							// 	}

							// })
						}

					})


					.catch(function(error) {
						console.log(error);
					});





			},


			register() {


				const params = new URLSearchParams();
				params.append('name', this.form.loginName);
				params.append('pwd', this.form.loginPassword);
				params.append('tel', this.form.telphone);
				params.append('code', this.form.smsVerificationCode);



				axios.post('http://doctor.com/api/login/register', params)
					.then((response) => {
						if (response.data.code == 3) {
							this.$message.alert('验证码错误！')
						} else if (response.data.code == 1) {
							this.$message.alert('用户已存在！')
						} else {
							sessionStorage.setItem("uid", response.data.data.uid);
							sessionStorage.setItem("uname", response.data.data.uname);


							this.$alert('操作成功!', this.alert_mes, {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.replace('/home')
								}
							});
						}



					})


					.catch(function(error) {
						console.log(error);
					});

			},


		}
	}
</script>

<style scoped>
	
	.el-form-item--small.el-form-item{
		margin-bottom: 30px;
	}
	
	.wrap {
		width: 1200px;
		margin: 20px auto;
	}

	.content {
		background: #FFFFFF;
		padding: 20px 90px 50px;
		margin-bottom: 50px;
	}

	.el-button--text {
		color: #3BD1DC !important;
	}


	.el-form {
 
		
		
			/* padding: 0px 250px; */
			padding: 0px 130px;
	}

	.el-form .el-radio-group {
		width: 100%;
	}

	.el-form .btn-next {
		width: 50%;
	}

	.el-form .captcha .text-input {
		padding-left: 0px !important;
	}

	.el-form .captcha .text-captcha {
		height: 35px;
	}

	.el-form .captcha .text-captcha img {
		margin-top: 2px;
		cursor: pointer;
	}

	.el-form .el-radio-group {
		text-align: center;
	}
</style>
